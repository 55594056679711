<template>
  <div>
      <svg
        class="svg-icon"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        viewBox="0 0 512 512"
      >
        <path
          d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16h368c17.7 0 32-14.3 32-32s-14.3-32-32-32zm352 240a32 32 0 1 1 0 64a32 32 0 1 1 0-64"
        ></path>
      </svg>
  </div>
</template>

<script>
export default {
  name: "WalletIcon",
  props: {
    width: {
      type: String,
      default: "48px",
    },
  },
};
</script>
